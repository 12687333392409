import React, { useState, useEffect } from "react";
import {
  TextField,
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { EditNav, CreateNav } from "../../../components/components";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useActions } from "../../../redux/actions";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const EditJobScreen = (props) => {
  const classes = useStyles();

  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [position, setPosition] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [weeklyHours, setWeeklyHours] = useState("");

  const [description, setDescription] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [textMessage, setTextMessage] = useState("");
  const [startOn, setStartOn] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("");

  const [emailInputTimer, setEmailInputTimer] = useState("");
  const [emailError, setEmailError] = useState(false);

  const types = [
    {
      key: "1",
      name: "Full Time",
      value: "fullTime",
    },
    {
      key: "2",
      name: "Part Time",
      value: "partTime",
    },
    {
      key: "3",
      name: "Seasonal",
      value: "seasonal",
    },
    {
      key: "=4",
      name: "Odd Job",
      value: "oddJob",
    },
  ];

  const { id } = useParams();
  const history = useHistory();

  const jobsList = useSelector((state) => state.jobs);
  const [selectedJob] = jobsList.filter((job) => job.jobId === id);

  const { jobs, JobsCreate, JobEdit } = useActions();

  let checker;
  if (id === "create") {
    checker = true;
  } else {
    checker = false;
  }

  useEffect(() => {
    setPosition(selectedJob?.position);
    setCompany(selectedJob?.company);
    setLocation(selectedJob?.location);
    setWeeklyHours(selectedJob?.weeklyHours);
    setType(selectedJob?.type);
    setDescription(selectedJob?.description);
    setExpiresAt(selectedJob?.expiresAt);
    setTextMessage(selectedJob?.text);
    setEmail(selectedJob?.email);
    setStartOn(selectedJob?.startOn);
  }, [id, jobsList]);

  useEffect(() => {
    if (
      position &&
      company &&
      location &&
      weeklyHours &&
      type &&
      startOn &&
      description &&
      email &&
      textMessage &&
      fieldsCorrectlyFormatted(weeklyHours, email, textMessage)
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    position,
    company,
    location,
    weeklyHours,
    type,
    startOn,
    description,
    email,
    textMessage,
  ]);

  useEffect(() => {
    clearTimeout(emailInputTimer);
    setEmailInputTimer(
      setTimeout(() => setEmailError(email && !validEmail(email)), 1000)
    );
  }, [email]);

  const handleUpdate = async () => {
    await JobEdit(id, {
      position,
      company,
      location,
      weeklyHours,
      type,
      description,
      textMessage,
      email,
      startOn,
      expiresAt,
    });
    history.push(`/jobs`);
  };

  const handleCreate = async () => {
    await JobsCreate({
      position,
      company,
      location,
      weeklyHours,
      type,
      description,
      textMessage,
      email,
      startOn,
    });
    history.push(`/jobs`);
  };

  return (
    <div className={classes.root}>
      {checker ? (
        <CreateNav
          text="Create Job Post"
          handleClick={handleCreate}
          handleCancel={() => history.push("/jobs")}
          handleDisable={buttonDisabled}
        />
      ) : (
        <EditNav
          text="Edit Job Post"
          handleClick={handleUpdate}
          handleCancel={() => history.push("/jobs")}
          handleDisable={buttonDisabled}
        />
      )}

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl variant="outlined" fullWidth={true}>
            <InputLabel id="author-select-input-outlined-label">
              Type
            </InputLabel>
            <Select
              labelId="author-select-outlined-label"
              id="author-select-outlined"
              value={type}
              onChange={(e) => setType(e.target.value)}
              label="Author"
              fullWidth={true}
            >
              {types.map((type) => (
                <MenuItem
                  key={type.id}
                  value={type.value}
                >{`${type.name}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Job Title"
            multiline
            rows={1}
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            variant="outlined"
            fullWidth={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginBottom: 10, marginTop: 10 }}>
            Position Details
          </Typography>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 5 }}>
              <TextField
                label="Job location"
                multiline
                rows={1}
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Company name"
                multiline
                rows={1}
                value={company}
                onChange={(e) => setCompany(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 5 }}>
              <TextField
                label="Weekly Hours"
                error={isNaN(weeklyHours) && weeklyHours}
                multiline
                rows={1}
                value={weeklyHours}
                onChange={(e) => setWeeklyHours(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  label="Start on"
                  multiline
                  rows={1}
                  value={startOn ? startOn : null}
                  onChange={(e) => setStartOn(e.format("YYYY-MM-DD"))}
                  inputVariant="outlined"
                  fullWidth={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
        </Grid>

        {selectedJob && (
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label="Expires At"
                multiline
                rows={1}
                value={expiresAt ? expiresAt : null}
                onChange={(e) => setExpiresAt(e.format("YYYY-MM-DD"))}
                variant="outlined"
                fullWidth={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginBottom: 10, marginTop: 10 }}>
            Contact Details
          </Typography>
          <Grid container>
            <Grid item xs={6} style={{ paddingRight: 5 }}>
              <TextField
                label="Phone Number"
                error={isNaN(textMessage) && textMessage}
                multiline
                rows={1}
                value={textMessage}
                onChange={(e) => setTextMessage(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Email"
                error={emailError}
                multiline
                rows={1}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h3" style={{ marginBottom: 10, marginTop: 10 }}>
            Job Description
          </Typography>
          <TextField
            label="Description"
            multiline
            rows={20}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            fullWidth={true}
          />
        </Grid>

        {/* <Grid item xs={9}>
          <TextField
            id="new-article-body"
            label="Article Body"
            multiline
            rows={20}
            defaultValue=""
            variant="outlined"
            fullWidth={true}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid> */}
      </Grid>
    </div>
  );
};
export default EditJobScreen;

const fieldsCorrectlyFormatted = (weeklyHours, email, textMessage) => {
  if (isNaN(weeklyHours)) return false;
  if (!validEmail(email)) return false;
  if (/[A-Za-z]/g.test(textMessage)) return false;
  return true;
};

const validEmail = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  )
    return true;
  return false;
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  imageContainer: {
    width: "100%",
    height: "300px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageElement: {
    borderRadius: "10px",
    width: "auto",
    maxWidth: "100%",
    height: "100%",
  },
}));
