import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { PageNav, TabSwitcher } from "../../components/components";

import BusinessProfiles from "./businessProfiles";
import AuthorProfiles from "./authorProfiles";

const Profiles = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  const changeTab = (val) => {
    setActiveTab(val);
  };

  let dynamicSection = <BusinessProfiles />;
  if (activeTab === 1) {
    dynamicSection = <AuthorProfiles />;
  }

  return (
    <div>
      <div className={classes.navWrapper}>
        <PageNav btn={"Create Profile"}>Profiles</PageNav>
        <TabSwitcher
          tabs={[
            {
              label: "Businesses",
            },
            {
              label: "Authors",
            },
          ]}
          activeTab={activeTab}
          onTabChange={(e, val) => changeTab(val)}
        />
      </div>

      <div>{dynamicSection}</div>
    </div>
  );
};
export default Profiles;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ff444b",
  },
  navWrapper: {
    marginBottom: "1rem",
    borderBottom: "1px solid #D8D8D8",
  },
}));
