import React, { useState } from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { ModalWrapper, UploadModal } from "../../components/components";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useActions } from "../../redux/actions";
import { CustomTable } from "../../components/components";

// HEAD CELLS
const headCells = [
  { id: "title", label: "Job" },
  { id: "type", label: "Type" },
  { id: "submittedAt", label: "Date" },
  { id: "options", label: "Options" },
];

function isValueInField(value, row) {
  const description = row.description?.toLowerCase() ?? "";
  const location = row.location?.toLowerCase() ?? "";
  const position = row.position?.toLowerCase() ?? "";
  const type = row.type.toLowerCase();
  const search = value.toLowerCase();

  return (
    description.includes(search) ||
    location.includes(search) ||
    position.includes(search) ||
    type.includes(search) ||
    row.postedAt.includes(search)
  );
}

const JobsScreen = () => {
  const classes = useStyles();
  const { jobs } = useActions();
  const data = useSelector((state) => state.jobs);
  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [modalOpen, setModalOpen] = useState(false);
  const [refetch, setRefetch] = useState(true);

  const { AdvertCreate } = useActions();
  const history = useHistory();

  React.useEffect(() => {
    init();
  }, [refetch]);

  const init = async () => {
    let { response } = await jobs();
  };

  const onRowClick = () => {
    history.push("/jobs/create/");
  };

  // SET SEARCH: Set search field listner
  const applySearch = (row) => {
    if (searchValue !== "") {
      return isValueInField(searchValue, row);
    }
    return row;
  };

  const onSearchChange = (val) => {
    setSearchValue(val);
  };

  return (
    <div>
      <div className={classes.navWrapper}>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onRowClick()}
        >
          New Job
        </Button>
      </div>

      <CustomTable
        tableType="job"
        tableDate={data || []}
        headCells={headCells}
        defualtOrder="itemStatus"
        applySearch={applySearch}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
export default JobsScreen;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ff444b",
  },
  navWrapper: {
    marginBottom: "1rem",
    borderBottom: "1px solid #D8D8D8",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
}));
