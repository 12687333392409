import React from "react";
import { Link } from "react-router-dom";

import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

import BusinessIcon from "@material-ui/icons/Business";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import WorkIcon from "@material-ui/icons/Work";
import { NearMeSharp } from "@material-ui/icons";

const SideBarItem = (props) => {
  const classes = useStyles();

  return (
    <Link to={`/${props.link}`} className={classes.linkfix}>
      <ListItem
        button
        key={props.name}
        style={{
          borderLeft: props.active
            ? "3px solid #0A3C70"
            : "3px solid transparent",
          paddingBottom: 15,
          paddingTop: 15,

          backgroundColor: props.active ? "#f7f7f7" : "transparent",
        }}
      >
        <ListItemIcon
          style={{
            color: props.active ? "#0A3C70" : "rgba(0, 0, 0, 0.54)",
          }}
        >
          <Icon name={props.name} />
        </ListItemIcon>
        <ListItemText
          style={{
            color: props.active ? "#0A3C70" : "inherit",
          }}
        >
          {props.name}
        </ListItemText>
      </ListItem>
    </Link>
  );
};

export default SideBarItem;

const Icon = (props) => {
  switch (props.name) {
    case "Authors":
      return <PeopleAltIcon />;
    case "Articles":
      return <MenuBookIcon />;
    case "Businesses":
      return <BusinessIcon />;
    case "Adverts":
      return <DashboardIcon />;
    case "Jobs":
      return <WorkIcon />;
  }
};

const useStyles = makeStyles((theme) => ({
  linkfix: {
    textDecoration: "none",
    color: "inherit",
  },
}));
