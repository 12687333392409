import {
  JOB_LIST,
  JOB_DELETE,
  JOB_VERIFY,
  JOB_CREATE,
  JOB_EDIT,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case JOB_LIST: {
      return action.payload;
    }
    case JOB_CREATE: {
      const newState = [...state, action.payload];
      return newState;
    }
    case JOB_DELETE: {
      const newState = state.filter(
        (job) => job.jobId !== action.payload.jobId
      );
      return newState;
    }
    case JOB_EDIT: {
      const newState = state.map((job) =>
        job.jobId !== action.payload.jobId ? job : [...job, ...action.payload]
      );
      return newState;
    }
    case JOB_VERIFY: {
      const newState = [...state, action.payload];
      return newState;
    }
    default:
      return state;
  }
};
