import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import {
  makeStyles,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  Button,
  Container,
} from "@material-ui/core";

// STATIC USER DATA IMPORTED FROM USER.JS ** API DEV - REMOVE
import { Logo, Profile } from "../../STATIC/user.js";

const drawerWidth = 240;

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogIn = () => {
    history.push("/login");
  };

  const classes = useStyles();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Container maxWidth="lg">
        <Toolbar
          style={{ justifyContent: "space-between", alignItems: "middle" }}
        >
          <Link to="/">
            <img alt="Logo" src={Logo} className={classes.logo} />
          </Link>

          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <img src={Profile} className={classes.profile} alt="" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              style={{ marginTop: 20 }}
            >
              <MenuItem onClick={handleLogIn}>Login</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  profile: {
    width: "2.6rem",
    height: "2.6rem",
    borderRadius: 30,
    cursor: "pointer",
  },
  logo: {
    width: "8rem",
  },
}));
