import {
  ARTICLE_CREATE,
  ARTICLES_LIST,
  ARTICLES_EDIT,
  ARTICLES_DELETE,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ARTICLES_LIST: {
      return action.payload;
    }
    case ARTICLE_CREATE: {
      const newState = [...state, action.payload];
      return newState;
    }
    case ARTICLES_DELETE: {
      const newState = state.filter(
        (article) => article.articleId !== action.payload.articleId
      );
      return newState;
    }
    case ARTICLES_EDIT: {
      const newState = state.map((article) =>
        article.articleId !== action.payload.articleId
          ? article
          : [...article, ...action.payload]
      );
      return newState;
    }

    default:
      return state;
  }
};
