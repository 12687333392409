import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { EditNav } from "../../components/components";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const EditProfile = () => {
  const classes = useStyles();
  const [profileType, setProfileType] = React.useState("10");

  // Social link
  const [socialOne, setSocialOne] = React.useState("");
  const [socialTwo, setSocialTwo] = React.useState("");
  const [socialThree, setSocialThree] = React.useState("");
  const [socialFour, setSocialFour] = React.useState("");

  // Hanlde profile type change
  const handleProfileTypeChange = (event) => {
    setProfileType(event.target.value);
  };

  // Hanlde social link option change
  const handleSocialOneChange = (event) => {
    setSocialOne(event.target.value);
  };
  const handleSocialTwoChange = (event) => {
    setSocialTwo(event.target.value);
  };
  const handleSocialThreeChange = (event) => {
    setSocialThree(event.target.value);
  };
  const handleSocialFourChange = (event) => {
    setSocialFour(event.target.value);
  };

  return (
    <div className={classes.root}>
      <EditNav btnText={"Composer"}>Edit Profile</EditNav>
      <div className={classes.formWrapper}>
        <div className={classes.formTop}>
          <div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">
                Author
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={profileType}
                onChange={handleProfileTypeChange}
                label="Author"
                fullWidth={true}
              >
                <MenuItem value={10}>Author</MenuItem>
                <MenuItem value={20}>Business</MenuItem>
              </Select>
            </FormControl>

            <TextField
              style={{ marginBottom: 20 }}
              id="filled-multiline-static"
              label="Name"
              multiline
              rows={1}
              defaultValue=""
              placeholder="John Doe"
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              style={{ marginBottom: 20 }}
              id="filled-multiline-static"
              label="Email"
              multiline
              rows={1}
              defaultValue=""
              placeholder="john.doe@sample-domain.com"
              variant="outlined"
              fullWidth={true}
            />
            <TextField
              style={{ marginBottom: 20 }}
              id="filled-multiline-static"
              label="Website"
              multiline
              rows={1}
              defaultValue=""
              placeholder="https://www.example.com"
              variant="outlined"
              fullWidth={true}
            />
          </div>

          <div className={classes.colRight}>
            IMPLEMENT PROFILE PICTURE HANDELER
          </div>
        </div>
      </div>
      <TextField
        style={{ marginBottom: 20 }}
        id="filled-multiline-static"
        label="Profile Body"
        multiline
        rows={10}
        defaultValue=""
        placeholder="Enter the profile body here"
        variant="outlined"
        fullWidth={true}
      />
      <div className={classes.socialWrapper}>
        <FormControl variant="outlined" className={classes.socialPicker}>
          <InputLabel id="demo-simple-select-outlined-label">Social</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={socialOne}
            onChange={handleSocialOneChange}
            label="Author"
            fullWidth={true}
          >
            <MenuItem value={10}>Facebook</MenuItem>
            <MenuItem value={20}>Instagram</MenuItem>
            <MenuItem value={30}>Twitter</MenuItem>
            <MenuItem value={40}>Youtube</MenuItem>
            <MenuItem value={50}>LinekdIn</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="filled-multiline-static"
          label="Social link"
          multiline
          rows={1}
          defaultValue=""
          placeholder="Enter the profile body here"
          variant="outlined"
          fullWidth={true}
        />
      </div>
      <div className={classes.socialWrapper}>
        <FormControl variant="outlined" className={classes.socialPicker}>
          <InputLabel id="demo-simple-select-outlined-label">Social</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={socialTwo}
            onChange={handleSocialTwoChange}
            label="Author"
            fullWidth={true}
          >
            <MenuItem value={10}>Facebook</MenuItem>
            <MenuItem value={20}>Instagram</MenuItem>
            <MenuItem value={30}>Twitter</MenuItem>
            <MenuItem value={40}>Youtube</MenuItem>
            <MenuItem value={50}>LinekdIn</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="filled-multiline-static"
          label="Social link"
          multiline
          rows={1}
          defaultValue=""
          placeholder="Enter the profile body here"
          variant="outlined"
          fullWidth={true}
        />
      </div>
      <div className={classes.socialWrapper}>
        <FormControl variant="outlined" className={classes.socialPicker}>
          <InputLabel id="demo-simple-select-outlined-label">Social</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={socialThree}
            onChange={handleSocialThreeChange}
            label="Author"
            fullWidth={true}
          >
            <MenuItem value={10}>Facebook</MenuItem>
            <MenuItem value={20}>Instagram</MenuItem>
            <MenuItem value={30}>Twitter</MenuItem>
            <MenuItem value={40}>Youtube</MenuItem>
            <MenuItem value={50}>LinekdIn</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="filled-multiline-static"
          label="Social link"
          multiline
          rows={1}
          defaultValue=""
          placeholder="Enter the profile body here"
          variant="outlined"
          fullWidth={true}
        />
      </div>
      <div className={classes.socialWrapper}>
        <FormControl variant="outlined" className={classes.socialPicker}>
          <InputLabel id="demo-simple-select-outlined-label">Social</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={socialFour}
            onChange={handleSocialFourChange}
            label="Author"
            fullWidth={true}
          >
            <MenuItem value={10}>Facebook</MenuItem>
            <MenuItem value={20}>Instagram</MenuItem>
            <MenuItem value={30}>Twitter</MenuItem>
            <MenuItem value={40}>Youtube</MenuItem>
            <MenuItem value={50}>LinekdIn</MenuItem>
          </Select>
        </FormControl>
        <TextField
          id="filled-multiline-static"
          label="Social link"
          multiline
          rows={1}
          defaultValue=""
          placeholder="Enter the profile body here"
          variant="outlined"
          fullWidth={true}
        />
      </div>
    </div>
  );
};
export default EditProfile;

const useStyles = makeStyles((theme) => ({
  root: {},
  formWrapper: {
    width: "100%",
  },
  formTop: {
    display: "flex",
    justifyContent: "space-between",
  },
  colRight: {
    backgroundColor: "#481e8a",
    flexGrow: 1,
  },
  formControl: {
    width: "20rem",
    marginBottom: 20,
  },
  socialPicker: {
    width: "20rem",
    marginBottom: 20,
    marginRight: 20,
  },
  socialWrapper: {
    display: "flex",
    width: "50rem",
  },
}));
