import {
  LOGOUT,
  LOGIN,
  ARTICLE_CREATE,
  ARTICLES_LIST,
  ARTICLES_DELETE,
  // ARTICLES_UPDATE,
  ARTICLE_IMAGEURL,
  ARTICLES_EDIT,
  BUSINESSES_LIST,
  BUSINESSES_DELETE,
  BUSINESS_IMAGEURL,
  AUTHORS_LIST,
  AUTHOR_CREATE,
  AUTHORS_DELETE,
  AUTHOR_UPDATE,
  AUTHOR_IMAGEURL,
  ADVERTS_LIST,
  ADVERTS_DELETE,
  UPLOAD_ON,
  UPLOAD_OFF,
  UPLOAD_ADD_CURRENT,
  UPLOAD_UPDATE_CURRENT,
  UPLOAD_DELETE_CURRENT,
  TAGS_LIST,
  ADVERT_CREATE,
  BUSINESS_CREATE,
  BUSINESS_UPDATE,
  JOB_DELETE,
  JOB_EDIT,
  JOB_LIST,
  JOB_VERIFY,
  JOB_CREATE,
} from "./types";
import { createAction } from "redux-actions";
import { Auth } from "aws-amplify";
import { useDispatch } from "react-redux";
import * as API from "./api";

/* AUTH */
const loginAction = createAction(LOGIN);
const logoutAction = createAction(LOGOUT);

const articleCreateAction = createAction(ARTICLE_CREATE);
const articlesAction = createAction(ARTICLES_LIST);
const articleDeleteAction = createAction(ARTICLES_DELETE);
const articleEditAction = createAction(ARTICLES_EDIT);
const articleUrlAction = createAction(ARTICLE_IMAGEURL);

// const articleUpdateAction = createAction(ARTICLES_UPDATE);
const jobAction = createAction(JOB_LIST);
const jobDeleteAction = createAction(JOB_DELETE);
const jobVerifyAction = createAction(JOB_VERIFY);
const jobsCreateAction = createAction(JOB_CREATE);
const jobEditAction = createAction(JOB_EDIT);

const businessAction = createAction(BUSINESSES_LIST);
const BusinessDeleteAction = createAction(BUSINESSES_DELETE);
const businessCreateAction = createAction(BUSINESS_CREATE);
const businessEditAction = createAction(BUSINESS_UPDATE);
const businessUrlAction = createAction(BUSINESS_IMAGEURL);

const authorsAction = createAction(AUTHORS_LIST);
const authorCreateAction = createAction(AUTHOR_CREATE);
const authorDeleteAction = createAction(AUTHORS_DELETE);
const authorEditAction = createAction(AUTHOR_UPDATE);
const authorUrlAction = createAction(AUTHOR_IMAGEURL);

const advertsAction = createAction(ADVERTS_LIST);
const advertDeleteAction = createAction(ADVERTS_DELETE);
const advertCreateAction = createAction(ADVERT_CREATE);

const uploadOnAction = createAction(UPLOAD_ON);
const uploadOffAction = createAction(UPLOAD_OFF);
const uploadAddCurrentAction = createAction(UPLOAD_ADD_CURRENT);
const uploadUpdateCurrentAction = createAction(UPLOAD_UPDATE_CURRENT);
const uploadDeleteCurrentAction = createAction(UPLOAD_DELETE_CURRENT);

const tagsAction = createAction(TAGS_LIST);

export const useActions = () => {
  const dispatch = useDispatch();

  return {
    Login: async (username, password) => {
      let response, error;
      try {
        response = await Auth.signIn(username, password);
        dispatch(loginAction(response));
      } catch (e) {
        console.log(e);
      }
      return { response, error };
    },

    /* LOG OUT */
    Logout: async () => {
      let response, error;
      try {
        response = await Auth.signOut();
        dispatch(logoutAction());
      } catch (e) {
        error = e;
      }
      return { response, error };
    },
    articles: async () => {
      let response, error;
      try {
        response = await API.articles.list();
        dispatch(articlesAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },

    ArticleDelete: async (articleId) => {
      let response, error;
      try {
        response = await API.articles.delete(articleId);
        dispatch(articleDeleteAction({ articleId }));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    articleUrl: async (files) => {
      let response, error;
      try {
        files.forEach(async (file) => {
          const { id, contentType, fileBinary, onProgress } = file;
          response = await API.articles.url({});
          const uploadUrl = response.data.uploadUrl;
          const source = response.data.source;
          localStorage.setItem("imagesource", source);
          await API.articles.upload(
            id,
            contentType,
            fileBinary,
            onProgress,
            uploadUrl
          );
          dispatch(articleUrlAction(response.data.photo));
        });
      } catch (e) {
        console.log("error", e);
        error = e;
      }
      return { response, error };
    },
    ArticleEdit: async (
      articleId,
      title,
      edition,
      authorId,
      datePublished,
      pictures,
      text,
      tags
    ) => {
      let response, error;
      try {
        response = await API.articles.edit(
          articleId,
          title,
          edition,
          authorId,
          datePublished,
          pictures,
          text,
          tags
        );
        dispatch(
          articleEditAction({
            articleId,
            title,
            edition,
            authorId,
            datePublished,
            pictures,
            text,
            tags,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    ArticleCreate: async (
      title,
      edition,
      authorId,
      datePublished,
      pictures,
      text,
      views,
      tags,
      fileBinary
    ) => {
      let response, error;
      try {
        response = await API.articles.create(
          title,
          edition,
          authorId,
          datePublished,
          pictures,
          text,
          views,
          tags,
          fileBinary
        );
        dispatch(
          articleCreateAction({
            title,
            edition,
            authorId,
            datePublished,
            pictures,
            text,
            views,
            tags,
            fileBinary,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    businesses: async () => {
      let response, error;
      try {
        response = await API.businesses.list();
        dispatch(businessAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    businessUrl: async (files) => {
      let response, error;
      try {
        files.forEach(async (file) => {
          const { id, contentType, fileBinary, onProgress } = file;
          response = await API.businesses.url({});
          const uploadUrl = response.data.uploadUrl;
          const source = response.data.source;
          localStorage.setItem("imagesource", source);
          await API.businesses.upload(
            id,
            contentType,
            fileBinary,
            onProgress,
            uploadUrl
          );
          dispatch(businessUrlAction(response.data.photo));
        });
      } catch (e) {
        console.log("error", e);
        error = e;
      }
      return { response, error };
    },
    BusinessDelete: async (profileId) => {
      let response, error;
      try {
        response = await API.businesses.delete(profileId);
        dispatch(BusinessDeleteAction({ profileId }));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    businessUpdate: async (
      address,
      biography,
      email,
      name,
      phoneNo,
      socials,
      website,
      picture,
      latitude,
      longitude
    ) => {
      let response, error;
      try {
        response = await API.businesses.update(
          address,
          biography,
          email,
          name,
          phoneNo,
          socials,
          website,
          picture,
          latitude,
          longitude
        );
        dispatch(
          businessEditAction({
            address,
            biography,
            email,
            name,
            phoneNo,
            socials,
            website,
            picture,
            latitude,
            longitude,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },

    BusinessCreate: async (
      address,
      biography,
      email,
      name,
      phoneNo,
      socials,
      website,
      latitude,
      longitude
    ) => {
      let response, error;
      try {
        response = await API.businesses.create(
          address,
          biography,
          email,
          name,
          phoneNo,
          socials,
          website,
          latitude,
          longitude
        );
        dispatch(
          businessCreateAction({
            address,
            biography,
            email,
            name,
            phoneNo,
            socials,
            website,
            latitude,
            longitude,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    authors: async () => {
      let response, error;
      try {
        response = await API.authors.list();
        dispatch(authorsAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    authorDelete: async (profileId) => {
      let response, error;
      try {
        response = await API.authors.delete(profileId);
        dispatch(authorDeleteAction({ profileId }));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    authorUrl: async (files) => {
      let response, error;
      try {
        files.forEach(async (file) => {
          const { id, contentType, fileBinary, onProgress } = file;
          response = await API.authors.url({});
          const uploadUrl = response.data.uploadUrl;
          const source = response.data.source;
          localStorage.setItem("imagesource", source);
          await API.authors.upload(
            id,
            contentType,
            fileBinary,
            onProgress,
            uploadUrl
          );
          dispatch(authorUrlAction(response.data.photo));
        });
      } catch (e) {
        console.log("error", e);
        error = e;
      }
      return { response, error };
    },
    authorCreate: async (name, picture) => {
      let response, error;
      try {
        response = await API.authors.create(name, picture);
        dispatch(
          authorCreateAction({
            name,
            picture,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    authorUpdate: async (name, picture) => {
      let response, error;
      try {
        response = await API.authors.update(name, picture);
        dispatch(
          authorEditAction({
            name,
            picture,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    adverts: async () => {
      let response, error;
      try {
        response = await API.adverts.list();
        dispatch(advertsAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    AdvertDelete: async (advertId) => {
      let response, error;
      try {
        response = await API.adverts.delete(advertId);
        dispatch(advertDeleteAction({ advertId }));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    AdvertCreate: async (files) => {
      let response, error;
      try {
        files.forEach(async (file) => {
          const { id, contentType, fileBinary, onProgress } = file;
          response = await API.adverts.create();
          const uploadUrl = response.data.uploadUrl;
          await API.adverts.upload(
            id,
            contentType,
            fileBinary,
            onProgress,
            uploadUrl
          );
          dispatch(advertCreateAction(response.data));
        });
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    tags: async () => {
      let response, error;
      try {
        response = await API.tags.list();
        dispatch(tagsAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },

    // JOBS
    jobs: async () => {
      let response, error;
      try {
        response = await API.jobs.list();
        dispatch(jobAction(response));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    JobsDelete: async (jobId) => {
      let response, error;
      try {
        response = await API.jobs.delete(jobId);
        dispatch(jobDeleteAction({ jobId }));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    JobsVerify: async (jobId) => {
      let response, error;
      try {
        response = await API.jobs.verify(jobId);
        dispatch(jobVerifyAction(jobId));
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    JobEdit: async (
      position,
      company,
      location,
      weeklyHours,
      type,
      description,
      textMessage,
      email,
      startOn,
      expiresAt
    ) => {
      let response, error;
      try {
        response = await API.jobs.edit(
          position,
          company,
          location,
          weeklyHours,
          type,
          description,
          textMessage,
          email,
          startOn,
          expiresAt
        );
        dispatch(
          jobEditAction({
            position,
            company,
            location,
            weeklyHours,
            type,
            description,
            textMessage,
            email,
            startOn,
            expiresAt,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },
    JobsCreate: async (
      position,
      company,
      location,
      weeklyHours,
      type,
      description,
      textMessage,
      email,
      startOn
    ) => {
      let response, error;
      try {
        response = await API.jobs.create(
          position,
          company,
          location,
          weeklyHours,
          type,
          description,
          textMessage,
          email,
          startOn
        );
        dispatch(
          jobsCreateAction({
            position,
            company,
            location,
            weeklyHours,
            type,
            description,
            textMessage,
            email,
            startOn,
          })
        );
      } catch (e) {
        console.log(e);
        error = e;
      }
      return { response, error };
    },

    UploadOn: () => dispatch(uploadOnAction()),
    UploadOff: () => dispatch(uploadOffAction()),
    UploadAddCurrent: (files) => dispatch(uploadAddCurrentAction(files)),
    UploadUpdateCurrent: (id, data) =>
      dispatch(uploadUpdateCurrentAction({ id, data })),
    UploadDeleteCurrent: (id) => dispatch(uploadDeleteCurrentAction({ id })),
  };
};
