import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import {
  useTheme,
  makeStyles,
  Drawer,
  IconButton,
  Divider,
  List,
} from "@material-ui/core";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import SideBarItem from "./sideBarItem";
// STATIC USER DATA IMPORTED FROM USER.JS ** API DEV - REMOVE
import { LogoDark } from "../../STATIC/user.js";
import MovaLogo from "../../assets/mova-logo.svg";
import MovaLogoSM from "../../assets/mova-logo-sm.svg";

const drawerWidth = 240;

const SideBar = (props) => {
  const open = props.open;
  const activeLink = props.activeLink;
  const setOpen = props.setOpen;

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.toolbar}>
        <Link to="/">
          <img alt="Dark Logo" src={LogoDark} className={classes.logoDark} />
        </Link>

        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon color="primary" />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </div>
      <Divider style={{ marginBottom: "1rem" }} />
      <div className={classes.toolbarContent}>
        <List>
          <SideBarItem
            link="authors"
            name="Authors"
            active={activeLink === 0}
          />
          <SideBarItem
            link="articles"
            name="Articles"
            active={activeLink === 1}
          />
          <SideBarItem
            link="businesses"
            name="Businesses"
            active={activeLink === 2}
          />
          <SideBarItem
            link="adverts"
            name="Adverts"
            active={activeLink === 3}
          />
          <SideBarItem link="jobs" name="Jobs" active={activeLink === 4} />
        </List>

        <div className={classes.toolbarFooter}>
          <img
            src={MovaLogo}
            alt="Mova Logo"
            className={classes.toolbarFooterLogo}
            style={{ display: open ? "inline-block" : "none" }}
          />
          <img
            src={MovaLogoSM}
            alt="Mova Logo Small"
            className={classes.toolbarFooterLogoSM}
            style={{ display: open ? "none" : "inline-block" }}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SideBar;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  },
  toolbarContent: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  toolbarFooter: {
    paddingBottom: "1.5rem",
    width: "100%",
    textAlign: "center",
  },
  toolbarFooterLogo: {
    width: 140,
  },
  toolbarFooterLogoSM: {
    height: 30,
  },
  logoDark: {
    width: "7rem",
  },
}));
