import React, { useState } from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Typography,
  Chip,
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import WorkIcon from "@material-ui/icons/Work";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BusinessIcon from "@material-ui/icons/Business";
import InfoIcon from "@material-ui/icons/Info";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailIcon from "@material-ui/icons/Mail";

import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useActions } from "../../redux/actions";
import moment from "moment";

const types = {
  all: "All",
  fullTime: "Full Time",
  partTime: "Part Time",
  seasonal: "Seasonal",
  oddJob: "Odd Job",
};

function isValueInField(value, position) {
  return position.toLowerCase().includes(value.toLowerCase());
}

const JobsScreen = () => {
  const classes = useStyles();
  const { jobs } = useActions();
  const data = useSelector((state) => state.jobs);
  const [searchValue, setSearchValue] = useState("");
  const [filterType, setFilterType] = useState("all");
  const [refetch, setRefetch] = useState(true);
  const history = useHistory();

  React.useEffect(() => {
    init();
  }, [refetch]);

  const init = async () => {
    let { response } = await jobs();
  };

  const onCardClick = (link) => {
    history.push(`jobs/${link}`);
  };

  // SET SEARCH: Set search field listner
  const applySearch = (row) => {
    if (searchValue !== "") {
      return isValueInField(searchValue, row.position);
    }
    return true;
  };

  const onSearchChange = (val) => {
    setSearchValue(val);
  };

  const handleCategoryChange = (type) => {
    filterType === type ? setFilterType("all") : setFilterType(type);
  };

  return (
    <div className={classes.jobScreen}>
      <div className={classes.navWrapper}>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search"
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
      </div>

      <div className={classes.categories}>
        {Object.entries(types).map((entry) => (
          <Chip
            variant={filterType === entry[0] ? "default" : "outlined"}
            label={entry[1]}
            clickable
            color="primary"
            onClick={() => {
              handleCategoryChange(entry[0]);
            }}
          />
        ))}
      </div>

      <Grid className={classes.grid} container spacing={3}>
        {data
          .filter((row) => row.verifiedStatus === true)
          .filter((row) => applySearch(row))
          .filter((row) => filterType == "all" || filterType === row.type)
          .map((row) => (
            <Grid item xs={4}>
              <Card className={classes.card}>
                <CardActionArea onClick={() => onCardClick(row.jobLink)}>
                  <CardContent>
                    <Typography variant="h2" style={{ marginBottom: 5 }}>
                      {row.position}
                    </Typography>

                    <ContentLine content={row.company} title="Company" />
                    <ContentLine content={row.location} title="Location" />
                    <ContentLine content={types[row.type]} title="Type" />
                    <ContentLine
                      content={`${row.weeklyHours} hours per week`}
                    />
                    <div className={classes.itemWrapper}>
                      <Typography variant="subtitle1">
                        Starting {moment(`${row.startOn}`).format("DD-MM-YY")}
                      </Typography>
                    </div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
      </Grid>
    </div>
  );
};
export default JobsScreen;

const ContentLine = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.itemWrapper}>
      <Icon title={props.title} />
      <Typography variant="subtitle1" style={{ marginBottom: 5 }}>
        {props.content}
      </Typography>
    </div>
  );
};

const Icon = (props) => {
  const classes = useStyles();

  switch (props.title) {
    case "Location":
      return <LocationOnIcon className={classes.icon} />;
    case "Company":
      return <BusinessIcon className={classes.icon} />;
    case "Type":
      return <WorkIcon className={classes.icon} />;
    default:
      return null;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ff444b",
  },
  navWrapper: {
    marginBottom: "1rem",
    borderBottom: "1px solid #D8D8D8",
    paddingBottom: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  itemWrapper: { display: "flex", justifyContent: "center" },
  jobScreen: { width: "50%", margin: "auto" },
  grid: {},
  icon: {
    fontSize: 15,
    marginRight: 5,
  },
  card: {
    textAlign: "center",
  },
  categories: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    marginBottom: "1rem",
  },
}));
