import { Auth } from "aws-amplify";
import axios from "axios";

const api = axios.create({
  baseURL: "https://9e45csmy3i.execute-api.eu-west-1.amazonaws.com/prod",
  timeout: 3000,
});

const createAuthorizationHeader = async () => {
  let session;
  try {
    session = await Auth.currentSession();
  } catch {
    return { Authorization: "Bearer public" };
  }
  if (!session) return { Authorization: "Bearer public" };
  const token = session.idToken.jwtToken;
  return {
    Authorization: `Bearer ${token}`,
  };
};

export const articles = {
  create: async (data) =>
    await api.post(`/articles/post`, data, {
      headers: await createAuthorizationHeader(),
    }),
  edit: async (articleId, data) =>
    await api.put(`/articles/edit/${articleId}`, data, {
      headers: await createAuthorizationHeader(),
    }),
  delete: async (articleId) =>
    await api.delete(`/articles/delete/${articleId}`, {
      headers: await createAuthorizationHeader(),
    }),
  url: async (data) =>
    await api.post(`/articles/url`, data, {
      headers: await createAuthorizationHeader(),
    }),
  upload: async (id, contentType, file, onProgress, uploadUrl) => {
    await axios
      .put(uploadUrl, new Blob([file], { type: contentType }), {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          onProgress(id, { progress });
        },
        headers: {
          "Content-Type": contentType,
        },
      })
      .then(() => {
        onProgress(id, {
          uploaded: true,
        });
      })
      .catch(() => {
        onProgress(id, {
          error: true,
        });
      });

    return;
  },
  list: async () => {
    const response01 = await api.get("/articles", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
};

export const businesses = {
  list: async () => {
    const response01 = await api.get("/businesses", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
  create: async (data) =>
    await api.post(`/businesses/post`, data, {
      headers: await createAuthorizationHeader(),
    }),
  delete: async (profileId) =>
    await api.delete(`/businesses/delete/${profileId}`, {
      headers: await createAuthorizationHeader(),
    }),
  url: async (data) =>
    await api.post(`/businesses/url`, data, {
      headers: await createAuthorizationHeader(),
    }),
  update: async (profileId, data) =>
    await api.put(`/businesses/edit/${profileId}`, data, {
      headers: await createAuthorizationHeader(),
    }),
  upload: async (id, contentType, file, onProgress, uploadUrl) => {
    await axios
      .put(uploadUrl, new Blob([file], { type: contentType }), {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          onProgress(id, { progress });
        },
        headers: {
          "Content-Type": contentType,
        },
      })
      .then(() => {
        onProgress(id, {
          uploaded: true,
        });
      })
      .catch(() => {
        onProgress(id, {
          error: true,
        });
      });

    return;
  },
};
export const authors = {
  list: async () => {
    const response01 = await api.get("/authors", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
  create: async (data) =>
    await api.post(`/authors/create`, data, {
      headers: await createAuthorizationHeader(),
    }),
  delete: async (profileId) =>
    await api.delete(`/authors/delete/${profileId}`, {
      headers: await createAuthorizationHeader(),
    }),
  url: async (data) =>
    await api.post(`/authors/url`, data, {
      headers: await createAuthorizationHeader(),
    }),
  update: async (profileId, data) =>
    await api.put(`/authors/edit/${profileId}`, data, {
      headers: await createAuthorizationHeader(),
    }),
  upload: async (id, contentType, file, onProgress, uploadUrl) => {
    await axios
      .put(uploadUrl, new Blob([file], { type: contentType }), {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          onProgress(id, { progress });
        },
        headers: {
          "Content-Type": contentType,
        },
      })
      .then(() => {
        onProgress(id, {
          uploaded: true,
        });
      })
      .catch(() => {
        onProgress(id, {
          error: true,
        });
      });

    return;
  },
};
export const adverts = {
  list: async () => {
    const response01 = await api.get("/adverts", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
  create: async (data) =>
    await api.post(`/adverts/post`, data, {
      headers: await createAuthorizationHeader(),
    }),
  delete: async (advertId) =>
    await api.delete(`/adverts/delete/${advertId}`, {
      headers: await createAuthorizationHeader(),
    }),
  upload: async (id, contentType, file, onProgress, uploadUrl) => {
    await axios
      .put(uploadUrl, new Blob([file], { type: contentType }), {
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));
          onProgress(id, { progress });
        },
        headers: {
          "Content-Type": contentType,
        },
      })
      .then(() => {
        onProgress(id, {
          uploaded: true,
        });
      })
      .catch(() => {
        onProgress(id, {
          error: true,
        });
      });
    return;
  },
};
export const tags = {
  list: async () => {
    const response01 = await api.get("/tags", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
};

export const jobs = {
  create: async (data) =>
    await api.post(`/jobs/post`, data, {
      headers: await createAuthorizationHeader(),
    }),
  delete: async (jobId) =>
    await api.delete(`/jobs/delete/${jobId}`, {
      headers: await createAuthorizationHeader(),
    }),
  verify: async (jobId) =>
    await api.post(`/jobs/verify/${jobId}`, "", {
      headers: await createAuthorizationHeader(),
    }),
  edit: async (jobId, data) =>
    await api.put(`/jobs/edit/${jobId}`, data, {
      headers: await createAuthorizationHeader(),
    }),
  list: async () => {
    const response01 = await api.get("/jobs", {
      headers: await createAuthorizationHeader(),
    });
    return [...response01.data];
  },
};
