import React, { useState } from "react";
import {
  Container,
  makeStyles,
  Typography,
  Card,
  CardContent,
  CardActionArea,
  Button,
} from "@material-ui/core";

import WorkIcon from "@material-ui/icons/Work";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BusinessIcon from "@material-ui/icons/Business";
import InfoIcon from "@material-ui/icons/Info";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import MailIcon from "@material-ui/icons/Mail";

import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useActions } from "../../../redux/actions";
import moment from "moment";

const types = {
  all: "All",
  fullTime: "Full Time",
  partTime: "Part Time",
  seasonal: "Seasonal",
  oddJob: "Odd Job",
};

const UserJobDetailScreen = (props) => {
  const classes = useStyles();

  const { link } = useParams();
  const { jobs } = useActions();
  const jobsList = useSelector((state) => state.jobs) || [];
  const [refetch, setRefetch] = useState(true);

  React.useEffect(() => {
    init();
  }, [refetch]);

  const init = async () => {
    let { response } = await jobs();
  };

  const age = (date) => {
    const diff = moment() - moment(`${date}`);
    return moment(diff).format("DDD");
  };

  const onContactDetailClick = () => {
    if (selectedJob?.email) {
      window.location = `mailto:${selectedJob.email}`;
    }
    return;
  };

  const [selectedJob] = jobsList.filter((job) => job.jobLink === link);
  if (!selectedJob) return null;

  const ContactDetails = () => {
    return (
      <CardContent>
        <CategoryTitle title="Contact Details" />
        <CategoryTitle title={selectedJob.text} />
        <CategoryTitle title={selectedJob.email} />
      </CardContent>
    );
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          align="center"
          style={{ marginBottom: 25, fontSize: 28, fontWeight: "bold" }}
        >
          {selectedJob.position}
        </Typography>

        <Category title="Company" content={selectedJob.company} />
        <Category title="Location" content={selectedJob.location} />
        <Category
          title={`${types[selectedJob.type]} Job`}
          content={`${selectedJob.weeklyHours} hours per week`}
        />
        <Category
          title="Start date"
          content={moment(`${selectedJob.startOn}`).format("DD-MM-YYYY")}
        />
        <Category title="Info" content={selectedJob.description} />

        <Card align="center" className={classes.card}>
          {selectedJob?.email ? (
            <CardActionArea onClick={onContactDetailClick}>
              <ContactDetails />
            </CardActionArea>
          ) : (
            <ContactDetails />
          )}
        </Card>

        <div className={classes.startOnWrapper}>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ marginBottom: 5 }}
          >
            Created {age(selectedJob.postedAt)} days ago
          </Typography>
        </div>
      </Container>
    </div>
  );
};
export default UserJobDetailScreen;

const Category = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CategoryTitle title={props.title} />
      <CategoryContent content={props.content} />
    </div>
  );
};

const CategoryTitle = (props) => {
  const classes = useStyles();

  if (!props.title) return null;
  return (
    <div className={classes.itemWrapper}>
      <Icon title={props.title} />
      <Typography
        className={classes.categoryTitle}
        variant="subtitle1"
        align="center"
      >
        {props.title}
      </Typography>
    </div>
  );
};

const Icon = (props) => {
  const classes = useStyles();

  if (validEmail(props.title)) return <MailIcon className={classes.icon} />;

  //Simple test for phone number
  if (!/[A-Za-z]/g.test(props.title))
    return <LocalPhoneIcon className={classes.icon} />;

  switch (props.title) {
    case "Location":
      return <LocationOnIcon className={classes.icon} />;
    case "Start date":
      return <AccessTimeIcon className={classes.icon} />;
    case "Company":
      return <BusinessIcon className={classes.icon} />;
    case "Full Time Job":
    case "Part Time Job":
    case "Seasonal Job":
    case "Odd Job Job":
      return <WorkIcon className={classes.icon} />;
    case "Info":
      return <InfoIcon className={classes.icon} />;
    default:
      return null;
  }
};

const CategoryContent = (props) => {
  return (
    <Typography variant="subtitle1" align="center" style={{ marginBottom: 5 }}>
      {props.content}
    </Typography>
  );
};

//Simple regex to test for a valid email adress
const validEmail = (title) => {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    title
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  wrapper: {
    flexDirection: "column",
    marginBottom: 25,
  },
  itemWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  icon: {
    fontSize: 18,
    marginRight: 5,
    fill: "grey",
  },
  startOnWrapper: {
    marginTop: "0.5rem",
  },
  card: {
    textJustify: "center",
  },
  categoryTitle: {
    marginBottom: 10,
    fontSize: 18,
    fontWeight: "500",
  },
  button: {
    align: "center",
  },
}));
