import React, { useEffect, useState } from "react";
import {
  makeStyles,
  TableRow,
  TableCell,
  Typography,
  Button,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ContactMailOutlined, Delete, Edit } from "@material-ui/icons";
import { AlertComponent } from "../../../components";
import { useActions } from "../../../../redux/actions";
import moment from "moment";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import WorkIcon from "@material-ui/icons/Work";
import TimerIcon from "@material-ui/icons/Timer";

function JobRow(props) {
  const classes = useStyles();
  const [jobId, setJobId] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const { row } = props;
  const history = useHistory();
  const { JobsDelete, JobsVerify } = useActions();

  useEffect(() => {
    setJobId(row.jobId);
  }, [row]);

  // HANDLE ROW CLICK
  const onRowClick = (id) => {
    history.push(`jobs/${id}`);
  };

  // HANDLE DELETE
  const handleVerify = async (id) => {
    let { error } = await JobsVerify(id);
    if (error) console.log(error.response ? error.response.data : error);
    setAlertOpen(false);
  };

  // HANDLE DELETE
  const handleDelete = async (id) => {
    let { error } = await JobsDelete(id);
    if (error) console.log(error.response ? error.response.data : error);
    setAlertOpen(false);
  };

  const getTagName = function (tagName) {
    let tagNameSelected;
    switch (tagName) {
      case "oddJob":
        tagNameSelected = "Odd Job's";
        break;

      case "partTime":
        tagNameSelected = "Part Time";
        break;

      case "fullTime":
        tagNameSelected = "Full Time";
        break;

      default:
        tagNameSelected = "seasonal";
    }
    return tagNameSelected;
  };

  return (
    <TableRow key={jobId} className={classes.row}>
      <TableCell>
        <Typography variant="h3" style={{ marginBottom: 5 }}>
          {row.position}
        </Typography>

        <div className={classes.itemWrapper}>
          <WorkIcon className={classes.icon} />
          <Typography variant="subtitle1" style={{ marginBottom: 5 }}>
            {row.company}
          </Typography>
        </div>
        <div className={classes.itemWrapper}>
          <LocationOnIcon className={classes.icon} />
          <Typography variant="subtitle1" style={{ marginBottom: 5 }}>
            {row.location}
          </Typography>
        </div>
        <div className={classes.itemWrapper}>
          <TimerIcon className={classes.icon} />
          <Typography variant="subtitle1">
            {row.weeklyHours} hours per week.
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography variant="h3">{getTagName(row.type)}</Typography>
      </TableCell>
      <TableCell>
        {!row.verifiedStatus ? (
          <Typography variant="h3">
            Submitted at {moment(`${row.submittedAt}`).format("MMM Do YYYY")}
          </Typography>
        ) : (
          <Typography variant="h3">
            Published at {moment(`${row.postedAt}`).format("MMM Do YYYY")}
          </Typography>
        )}
      </TableCell>
      <TableCell align="right" style={{ width: 150 }}>
        <div style={{ display: "flex" }}>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={() => onRowClick(row.jobId)}
          >
            <Edit />
          </IconButton>
          {!row.verifiedStatus && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleVerify(row.jobId);
                row.verifiedStatus = true;
              }}
            >
              Approve
            </Button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

const useStyles = makeStyles((theme) => ({
  row: {
    cursor: "pointer",
  },
  imageWrapper: {
    width: "3rem",
    height: "3rem",
    backgroundColor: "#000",
    boxShadow: "2px 0px 8px rgba(0,0,0, .1)",
    borderRadius: 2,
  },
  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  itemWrapper: {
    display: "flex",
    alignItems: "middle",
  },

  icon: {
    fontSize: 15,
    marginRight: 5,
  },
}));

export default JobRow;
