import React, { useState, useEffect } from "react";
import {
  TextField,
  makeStyles,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  EditNav,
  CreateNav,
  ModalWrapper,
  UploadModal,
} from "../../../components/components";
import { useActions } from "../../../redux/actions";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import BackupIcon from "@material-ui/icons/Backup";

const EditBusinessScreen = (props) => {
  const classes = useStyles();
  const [image, setImage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [authchange, setAuthchange] = useState([]);

  const [address, setAddress] = useState("");
  const [biography, setBiography] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [website, setWebsite] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [county, setCounty] = useState("");
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [postCode, setPostCode] = useState("");
  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [twitter, setTwitter] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const {
    businessUrl,
    BusinessCreate,
    authors,
    businesses,
    businessUpdate,
  } = useActions();

  const { id } = useParams();
  const history = useHistory();

  const articless = useSelector((state) => state.articles);
  const authorState = useSelector((state) => state.authors);
  const business = useSelector((state) => state.businesses);

  useEffect(() => {
    const [selectedBusiness] = business.filter(
      (business) => business.profileId === id
    );
    authors();
    businesses();
    setAuthchange(authorState);
    setName(selectedBusiness?.name);
    setImage(selectedBusiness?.picture);
    setAddress(selectedBusiness?.address);
    setBiography(selectedBusiness?.biography);
    setEmail(selectedBusiness?.email);
    setPhoneNo(selectedBusiness?.phoneNo);
    setWebsite(selectedBusiness?.website);
    setCity(selectedBusiness?.address?.countryCode);
    setCountryCode(selectedBusiness?.address?.countryCode);
    setCounty(selectedBusiness?.address.county);
    setLine1(selectedBusiness?.address?.line1);
    setLine2(selectedBusiness?.address?.line2);
    setPostCode(selectedBusiness?.address?.postCode);
    setFacebook(selectedBusiness?.socials?.facebook);
    setInstagram(selectedBusiness?.socials?.instagram);
    setTwitter(selectedBusiness?.socials?.twitter);
    setLinkedin(selectedBusiness?.socials?.linkedin);
    setBiography(selectedBusiness?.biography);
    setLatitude(selectedBusiness?.latitude);
    setLongitude(selectedBusiness?.longitude);
    localStorage.removeItem("imagesource");
  }, [id, articless]);

  useEffect(() => {
    if (name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [name]);

  const handleCreate = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = image;
    }
    await BusinessCreate({
      address: { city, countryCode, county, line1, line2, postCode },
      biography,
      email,
      name,
      phoneNo,
      socials: { facebook, instagram, linkedin, twitter },
      website,
      latitude,
      longitude,
      picture,
    });
    history.push("/businesses");
  };
  const handleUpdate = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = image;
    }
    await businessUpdate(id, {
      address: { city, countryCode, county, line1, line2, postCode },
      biography,
      email,
      name,
      phoneNo,
      socials: { facebook, instagram, linkedin, twitter },
      website,
      latitude,
      longitude,
      picture,
    });
    history.push("/businesses");
  };

  const businessSource = async () => {
    setModalOpen(false);
    if (localStorage.getItem("imagesource")) {
      setImage(localStorage.getItem("imagesource"));
    }
  };

  let checker;
  if (id === "create") {
    checker = true;
  } else {
    checker = false;
  }

  return (
    <div className={classes.root}>
      {checker ? (
        <CreateNav
          text="Create Business"
          handleClick={handleCreate}
          handleCancel={() => history.push("/businesses")}
          handleDisable={buttonDisabled}
        />
      ) : (
        <EditNav
          text="Edit Business"
          handleClick={handleUpdate}
          handleCancel={() => history.push("/businesses")}
        />
      )}
      <ModalWrapper
        title="Business Image"
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        // handleClose={() => setModalOpen(false)}
        modalBody={
          <UploadModal
            type="photo"
            maxFiles="1"
            handleClose={businessSource}
            handleCreate={businessUrl}
          />
        }
      />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                id="new-business-name"
                label="Name"
                placeholder="MOVA Ireland Limited"
                rows={1}
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                id="new-business-phone-no"
                label="Phone number"
                placeholder="0239876700"
                rows={1}
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                id="new-business-email"
                label="Email address"
                placeholder="john.doe@example.com"
                rows={1}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.formItem}
                id="new-business-website"
                label="Website"
                placeholder="example.com"
                rows={1}
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Grid container spacing={1} className={classes.uploadBox}>
            <Grid item xs={12} className={classes.imageContainer}>
              {image ? (
                <img
                  src={image}
                  className={classes.imageElement}
                  alt="Business Cover"
                />
              ) : (
                <Typography variant="overline">
                  No business image uploaded.
                </Typography>
              )}
            </Grid>
            <Grid>
              <Button
                variant="contained"
                color="primary"
                style={{ marginBottom: "10px" }}
                onClick={() => setModalOpen(true)}
                startIcon={<BackupIcon />}
              >
                {image ? "Replace image" : "Upload Image"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes.sectionWrapper}>
        <Typography variant="h3" style={{ marginBottom: 20 }}>
          Business Address:
        </Typography>
        <TextField
          className={classes.formItem}
          id="new-business-add-line-1"
          label="Addres Line 1"
          placeholder="5 Pearce Street"
          value={line1}
          onChange={(e) => setLine1(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          className={classes.formItem}
          id="new-business-add-line-2"
          label="Address Line 2"
          placeholder="Kinsale"
          value={line2}
          onChange={(e) => setLine2(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />

        <div className={classes.sectionWrapper}>
          <TextField
            label="Biography"
            multiline
            rows={10}
            variant="outlined"
            fullWidth={true}
            value={biography}
            onChange={(e) => setBiography(e.target.value)}
          />
        </div>

        <div className={classes.sectionWrapper}>
          <Typography variant="h3" style={{ marginBottom: 5 }}>
            Location on Map
          </Typography>
          <Typography variant="body1" style={{ marginBottom: 10 }}>
            NB: Only populate if you want to add a pin to the map.
          </Typography>
          <div className={classes.geoLocationWrapper}>
            <TextField
              id="new-business-pos-latitude"
              label="Latitude"
              placeholder="51.706229570212784"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
              variant="outlined"
              fullWidth={true}
              style={{ marginRight: 10 }}
            />
            <TextField
              id="new-business-pos-longitude"
              label="Longitude"
              placeholder="-8.522193835499504"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
              variant="outlined"
              fullWidth={true}
            />
          </div>
        </div>
      </div>

      <div className={classes.sectionWrapper}>
        <Typography variant="h3" style={{ marginBottom: 20 }}>
          Social Media URL's:
        </Typography>
        <TextField
          id="new-business-facebook"
          className={classes.formItem}
          label="Facebook"
          placeholder="https://www.facebook.com/business-url"
          value={facebook}
          onChange={(e) => setFacebook(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          className={classes.formItem}
          label="Instagram"
          id="new-business-instagram"
          placeholder="https://www.instagram.com/business-url"
          value={instagram}
          onChange={(e) => setInstagram(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          className={classes.formItem}
          label="LinkedIn"
          id="new-business-linkedin"
          placeholder="https://www.linkedin.com/business-url"
          value={linkedin}
          onChange={(e) => setLinkedin(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />
        <TextField
          className={classes.formItem}
          label="Twitter"
          id="new-business-twitter"
          placeholder="https://www.twitter.com/business-url"
          value={twitter}
          onChange={(e) => setTwitter(e.target.value)}
          variant="outlined"
          fullWidth={true}
        />
      </div>
    </div>
  );
};
export default EditBusinessScreen;

const useStyles = makeStyles((theme) => ({
  root: {},
  formWrapper: {
    width: "100%",
    marginBottom: 30,
    //backgroundColor: "#694bf3",
    //display: "flex",
  },
  formControl: {
    width: "20rem",
    marginRight: 20,
    //backgroundColor: "#694bf3",
  },
  headerWrapper: {
    display: "fex",
    backgroundColor: "#694bf3",
    flexDirection: "row",
  },
  imageWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  articleImage: {
    height: "12rem",
    borderRadius: 10,
    marginBottom: 10,
  },
  formItem: {
    marginBottom: 15,
  },
  form: {
    paddingRight: 20,
  },
  geoLocationWrapper: {
    display: "flex",
  },
  sectionWrapper: {
    padding: "15px 0",
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageContainer: {
    width: "100%",
    height: "280px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageElement: {
    borderRadius: "10px",
    width: "auto",
    maxWidth: "100%",
    height: "100%",
    maxHeight: "100%",
  },
}));
