import { ADVERTS_LIST, ADVERTS_DELETE } from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADVERTS_LIST: {
      return action.payload;
    }
    case ADVERTS_DELETE: {
      const newState = state.filter(
        (advert) => advert.advertId !== action.payload.advertId
      );
      return newState;
    }
    default:
      return state;
  }
};
