import React, { useState } from "react";
import {
  makeStyles,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import { useActions } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { CustomTable } from "../../components/components";

// HEAD CELLS
const headCells = [
  { id: "title", label: "Image" },
  { id: "title", label: "Description" },
  { id: "options", label: "Options" },
];

function isValueInField(value, list) {
  return list.toLowerCase().includes(value.toLowerCase());
}

const ArticlesListScreen = () => {
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { articles, ArticleEdit } = useActions();
  const data = useSelector((state) => state.articles);
  const history = useHistory();

  const onRowClick = () => {
    history.push(`articles/create/`);
  };

  React.useEffect(() => {
    setTimeout(init, 500);
  }, []);

  const init = async () => {
    let { response } = await articles();
  };

  // SET SEARCH: Set search field listner
  const applySearch = (row) => {
    if (searchValue !== "") {
      return isValueInField(searchValue, row.title);
    }
    return row;
  };
  const onSearchChange = (val) => {
    setSearchValue(val);
  };

  return (
    <div>
      <div className={classes.navWrapper}>
        <TextField
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          placeholder="Search articles"
          value={searchValue}
          onChange={(e) => onSearchChange(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => onRowClick()}
        >
          New article
        </Button>
      </div>
      <CustomTable
        tableType=""
        tableDate={data || []}
        headCells={headCells}
        defualtOrder="itemStatus"
        applySearch={applySearch}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </div>
  );
};
export default ArticlesListScreen;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#ff444b",
  },
  navWrapper: {
    marginBottom: "1rem",
    borderBottom: "1px solid #D8D8D8",
    paddingBottom: "1rem",
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-between",
  },
}));
