import React, { useState, useEffect } from "react";
import {
  TextField,
  makeStyles,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Button,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  EditNav,
  CreateNav,
  ModalWrapper,
  UploadModal,
} from "../../../components/components";
import BackupIcon from "@material-ui/icons/Backup";
import { useActions } from "../../../redux/actions";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const EditArticleScreen = (props) => {
  const classes = useStyles();
  const [tag, setTag] = useState([]);
  const [image, setImage] = useState("");
  const [edition, setEdition] = useState("2020");
  const [authorId, setAuthorId] = useState("");
  const [text, setText] = useState("");
  const [datePublished, setDatePublished] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [authchange, setAuthchange] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);

  const { articleUrl, ArticleEdit, ArticleCreate, authors, tags } =
    useActions();
  const { id } = useParams();
  const history = useHistory();

  const articlesList = useSelector((state) => state.articles);
  const authorState = useSelector((state) => state.authors);
  authorState.sort((a, b) => a.name.localeCompare(b.name));
  const tagState = useSelector((state) => state.tags);
  tagState.sort((a, b) => a.localeCompare(b));
  const [selectedArticle] = articlesList.filter(
    (article) => article.articleId === id
  );

  useEffect(() => {
    authors();
    tags();
    setAuthchange(authorState);
    setTag(selectedArticle?.tags);
    setTitle(selectedArticle?.title);
    setEdition(selectedArticle?.edition);
    setImage(selectedArticle?.picture);
    setText(selectedArticle?.text);
    setDatePublished(selectedArticle?.datePublished);
    setAuthorId(selectedArticle?.authorId);
    localStorage.removeItem("imagesource");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, articlesList]);

  useEffect(() => {
    if (title) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [title]);

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const articleSource = async () => {
    setModalOpen(false);
    if (localStorage.getItem("imagesource")) {
      setImage(localStorage.getItem("imagesource"));
    }
  };

  const handleUpdate = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = image;
    }
    await ArticleEdit(id, {
      title,
      edition: "2020",
      authorId,
      datePublished,
      pictures: [picture],
      text,
      tags: [tag],
    });
    history.push("/articles");
  };
  const handleCreate = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = "";
    }
    await ArticleCreate({
      title,
      edition,
      authorId,
      datePublished,
      pictures: [picture],
      text,
      tags: [tag],
    });
    history.push(`/`);
  };

  const { articles } = useActions();
  const init = async () => {
    let { response } = await articles();
    // if (error) console.log(error.response ? error.response.data : error);
    // if (response) console.log(response);
  };
  let checker;
  if (id === "create") {
    checker = true;
  } else {
    checker = false;
  }

  useEffect(() => {
    init();
  }, []);

  return (
    <div className={classes.root}>
      {checker ? (
        <CreateNav
          text="Create Article"
          handleClick={handleCreate}
          handleCancel={() => history.push("/articles")}
          handleDisable={buttonDisabled}
        />
      ) : (
        <EditNav
          text="Edit Article"
          handleClick={handleUpdate}
          handleCancel={() => history.push("/articles")}
        />
      )}

      <ModalWrapper
        title="Upload Article Image"
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        modalBody={
          <UploadModal
            type="photo"
            maxFiles="1"
            handleClose={articleSource}
            handleCreate={articleUrl}
          />
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={9}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="create-new-article-title"
                label="Article Title"
                multiline
                rows={1}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} className={classes.imageContainer}>
              {image ? (
                <img
                  src={image}
                  className={classes.imageElement}
                  alt="Article Cover"
                />
              ) : (
                <Typography variant="overline">No image uploaded.</Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} sm={4} md={3} xl={3}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                id="article-published-date"
                label="Date Published"
                value={datePublished}
                onChange={(e) => setDatePublished(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="author-select-input-outlined-label">
                  Author
                </InputLabel>
                <Select
                  labelId="author-select-outlined-label"
                  id="author-select-outlined"
                  value={authorId}
                  onChange={(e) => setAuthorId(e.target.value)}
                  label="Author"
                  fullWidth={true}
                >
                  {authorState.sort().map((p) => (
                    <MenuItem
                      key={p.profileId}
                      value={p.profileId}
                    >{`${p.name}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth={true}>
                <InputLabel id="tag-select-input-outlined-label">
                  Tag
                </InputLabel>
                <Select
                  labelId="tag-select-outlined-label"
                  id="tag-select-outlined"
                  value={tag}
                  onChange={handleTagChange}
                  label="Tag"
                  fullWidth={true}
                >
                  <MenuItem value={""}>-</MenuItem>
                  {tagState.map((p) => (
                    <MenuItem key={p} value={p}>{`${p}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setModalOpen(true)}
                startIcon={<BackupIcon />}
              >
                {image ? "Replace image" : "Upload Image"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <TextField
            id="new-article-body"
            label="Article Body"
            multiline
            rows={20}
            defaultValue=""
            variant="outlined"
            fullWidth={true}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};
export default EditArticleScreen;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  imageContainer: {
    width: "100%",
    height: "300px",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  imageElement: {
    borderRadius: "10px",
    width: "auto",
    maxWidth: "100%",
    height: "100%",
  },
}));
