import {
  BUSINESSES_LIST,
  BUSINESSES_DELETE,
  BUSINESS_CREATE,
  BUSINESS_UPDATE,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESSES_LIST: {
      return action.payload;
    }
    case BUSINESS_CREATE: {
      const newState = [...state, action.payload];
      return newState;
    }

    case BUSINESSES_DELETE: {
      const newState = state.filter(
        (article) => article.articleId !== action.payload.articleId
      );
      return newState;
    }
    case BUSINESS_UPDATE: {
      const newState = state.map((business) =>
        business.profileId !== action.payload.profileId
          ? business
          : [...business, ...action.payload]
      );
      return newState;
    }
    default:
      return state;
  }
};
