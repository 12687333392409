import {
  ARTICLES_UPDATE,
  AUTHORS_LIST,
  AUTHOR_CREATE,
  AUTHOR_UPDATE,
  AUTHORS_DELETE,
} from "../types";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHORS_LIST: {
      return action.payload;
    }
    case AUTHOR_CREATE: {
      const newState = [...state, action.payload];
      return newState;
    }
    case AUTHORS_DELETE: {
      const newState = state.filter(
        (author) => author.profileId !== action.payload.profileId
      );
      return newState;
    }
    case ARTICLES_UPDATE: {
      const newState = state.map((author) =>
        author.profileId !== action.payload.profileId
          ? author
          : [...author, ...action.payload]
      );
      return newState;
    }
    default:
      return state;
  }
};
