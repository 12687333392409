import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { LogoDark } from "../../STATIC/user.js";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useActions } from "../../redux/actions";

const LoginForm = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState("");
  const [user, setUser] = React.useState();
  const [state, setState] = useState("login");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { Login } = useActions();
  const history = useHistory();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (state !== "login") return;
    setError("");
    setLoading(true);
    let response;
    try {
      response = await Auth.signIn(email, password);
    } catch (e) {
      setError(e.message);
      setLoading(false);
      return;
    }
    if (response.challengeName === "NEW_PASSWORD_REQUIRED") {
      setUser(response);
      setState("changePassword");
      setLoading(false);
      return;
    }
    //setLoading(false);
    await Login(email, password);
    await history.push("/");
  };

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    if (state !== "changePassword") return;
    if (newPassword !== repeatPassword) {
      setError("The passwords do not match.");
      return;
    }
    setError("");
    setLoading(true);
    let response;
    try {
      response = await Auth.completeNewPassword(user, newPassword);
      setLoading(false);
      setPassword("");
      setState("login");
    } catch (e) {
      setError(e.message);
      setLoading(false);
      return;
    }
    /*
    try {
      response = await Auth.currentSession();
    } catch (e) {
      setError(e);
      setLoading(false);
      return;
    }
    setLoading(true);
    await Login(email, password);
    await history.push("/");*/
  };

  if (state === "changePassword")
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Container className={classes.container}>
          <img src={LogoDark} className={classes.logo} alt="logo" />
          <Typography variant="h5">Change your password</Typography>
          <form onSubmit={handlePasswordChange} className={classes.form}>
            <TextField
              variant="outlined"
              fullWidth
              required
              id="new-password"
              name="new-password"
              type="password"
              label="New Password"
              autoComplete="current-password"
              className={classes.inputField}
              value={newPassword}
              onChange={(event) => setNewPassword(event.target.value)}
              disabled={loading}
            />
            <TextField
              variant="outlined"
              fullWidth
              required
              id="repeat-password"
              name="repeat-password"
              type="password"
              label="Repeat Password"
              autoComplete="current-password"
              className={classes.inputField}
              value={repeatPassword}
              onChange={(event) => setRepeatPassword(event.target.value)}
              disabled={loading}
            />
            {error === "" ? null : (
              <Alert severity="error" onClose={() => setError("")}>
                {error}
              </Alert>
            )}
            <Button
              type="submit"
              fullWidth
              color="primary"
              variant="contained"
              className={classes.submit}
              disabled={loading}
            >
              {loading ? "Please wait..." : "Change Password"}
            </Button>
          </form>
        </Container>
      </Container>
    );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Container className={classes.container}>
        <img src={LogoDark} className={classes.logo} />
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            fullWidth
            required
            id="email"
            name="email"
            label="Email Address"
            autoComplete="email"
            autoFocus
            placeholder="john@john-doe.com"
            className={classes.inputField}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            disabled={loading}
          />
          <TextField
            variant="outlined"
            fullWidth
            required
            id="password"
            name="password"
            type="password"
            label="Password"
            autoComplete="current-password"
            className={classes.inputField}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            disabled={loading}
          />
          {error === "" ? null : (
            <Alert severity="error" onClose={() => setError("")}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            color="primary"
            variant="contained"
            className={classes.submit}
            disabled={loading}
          >
            {loading ? "Please wait..." : "Login"}
          </Button>
        </form>
        <Typography variant="body1" className={classes.copyright}>
          &copy; 2020 by &nbsp;
          <Link
            to="https://www.mova.ie"
            target="_blank"
            className={classes.copyrightLink}
          >
            MOVA
          </Link>
        </Typography>
        <Typography variant="body1" className={classes.impress}>
          <Link to="https://www.mova.ie" target="_blank">
            Terms & Conditions
          </Link>
        </Typography>
      </Container>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(12),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
  inputField: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
  privacyNote: {
    marginTop: 20,
    fontSize: "0.9em",
    textAlign: "center",
  },
  copyright: {
    marginTop: 20,
    fontSize: "1em",
    fontWeight: "600",
    textAlign: "center",
  },
  copyrightLink: {
    textDecoration: "none",
    color: "#555",
  },
  impress: {
    fontSize: "0.9em",
    textAlign: "center",
  },
  logo: {
    width: "15em",
    marginBottom: "2em",
  },
}));

export default LoginForm;
