export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export const ARTICLE_CREATE = "ARTICLE_CREATE";
export const ARTICLES_LIST = "ARTICLES_LIST";
export const ARTICLES_DELETE = "ARTICLES_DELETE";
export const ARTICLES_UPDATE = "ARTICLES_UPDATE";
export const ARTICLES_EDIT = "ARTICLES_EDIT";
export const ARTICLE_IMAGEURL = "ARTICLE_IMAGEURL";

export const JOB_LIST = "JOB_LIST";
export const JOB_DELETE = "JOB_DELETE";
export const JOB_VERIFY = "JOB_VERIFY";
export const JOB_EDIT = "JOB_EDIT";
export const JOB_CREATE = "JOB_CREATE";

export const BUSINESSES_LIST = "BUSINESSES_LIST";
export const BUSINESSES_DELETE = "BUSINESSES_DELETE";
export const BUSINESS_CREATE = "BUSINESS_CREATE";
export const BUSINESS_UPDATE = "BUSINESS_UPDATE";
export const BUSINESS_IMAGEURL = "BUSINESS_IMAGEURL";

export const AUTHORS_LIST = "AUTHORS_LIST";
export const AUTHOR_CREATE = "AUTHOR_CREATE";
export const AUTHOR_UPDATE = "AUTHOR_UPDATE";
export const AUTHOR_IMAGEURL = "AUTHOR_IMAGEURL";
export const AUTHORS_DELETE = "AUTHORS_DELETE";

export const ADVERTS_LIST = "ADVERTS_LIST";
export const ADVERTS_DELETE = "ADVERTS_DELETE";
export const ADVERT_CREATE = "ADVERT_CREATE";

export const UPLOAD_MODAL_OPEN = "UPLOAD_MODAL_OPEN";
export const UPLOAD_MODAL_CLOSE = "UPLOAD_MODAL_CLOSE";
export const UPLOAD_ON = "UPLOAD_ON";
export const UPLOAD_OFF = "UPLOAD_OFF";
export const UPLOAD_ADD_CURRENT = "UPLOAD_ADD_CURRENT";
export const UPLOAD_UPDATE_CURRENT = "UPLOAD_UPDATE_CURRENT";
export const UPLOAD_DELETE_CURRENT = "UPLOAD_DELETE_CURRENT";

export const TAGS_LIST = "TAGS_LIST";
