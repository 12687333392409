import React, { useState, useEffect } from "react";
import { makeStyles, CssBaseline, Paper } from "@material-ui/core";
import { Route, Switch, Redirect } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Amplify from "aws-amplify";
import { useSelector } from "react-redux";

import { Header, PublicHeader, SideBar } from "../components/components";

import {
  Articles,
  EditArticleScreen,
  AuthorsListScreen,
  AuthorDetailScreen,
  BusinessScreen,
  BusinessDetailScreen,
  AdvertScreen,
  EditBusinessScreen,
  JobsScreen,
  EditJobScreen,
  UserJobsScreen,
  UserJobDetailScreen,
} from "../screens/allScreens";

// STATIC LOGIN IMPORT
import LoginForm from "../screens/login/index";

export default function Router() {
  const classes = useStyles();
  const [activeLink, setActiveLink] = useState(0);
  const [open, setOpen] = useState(true);

  let location = useLocation();

  const session = useSelector((state) => state.session);
  const isLoggedIn = Object.keys(session).length > 0;

  useEffect(() => {
    if (location.pathname === "/authors") {
      setActiveLink(0);
    } else if (location.pathname === "/articles") {
      setActiveLink(1);
    } else if (location.pathname === "/businesses") {
      setActiveLink(2);
    } else if (location.pathname === "/adverts") {
      setActiveLink(3);
    } else if (location.pathname === "/jobs") {
      setActiveLink(4);
    }
  });
  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: "eu-west-1",
        userPoolId: "eu-west-1_r3W5kZk8w",
        userPoolWebClientId: "29qu9atp2vnmq4nkog378t4ma6",
      },
    });
  }, []);

  if (!isLoggedIn) {
    if (location.pathname === "/login")
      return <Route exact path="/login" component={LoginForm} />;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <PublicHeader />
        <main className={classes.content}>
          <Paper style={{ height: "100%" }}>
            <div className={classes.wrapper}>
              <div className={classes.toolbar} />
              <Switch>
                <Route exact path="/jobs" component={UserJobsScreen} />
                <Route
                  exact
                  path="/jobs/:link"
                  component={UserJobDetailScreen}
                />
                <Route exact path="/">
                  <Redirect to="/jobs" />
                </Route>
                <Route component={UserJobsScreen} />
              </Switch>
            </div>
          </Paper>
        </main>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Header open={open} setOpen={setOpen} />
      <SideBar open={open} activeLink={activeLink} setOpen={setOpen} />
      <main className={classes.content}>
        <Paper style={{ height: "100%" }}>
          <div className={classes.wrapper}>
            <div className={classes.toolbar} />
            <Switch>
              <Route exact path="/authors/:id" component={AuthorDetailScreen} />
              <Route exact path="/authors" component={AuthorsListScreen} />
              <Route exact path="/author/:id" component={AuthorDetailScreen} />

              <Route exact path="/articles/:id" component={EditArticleScreen} />
              <Route exact path="/articles" component={Articles} />

              <Route exact path="/jobs" component={JobsScreen} />
              <Route exact path="/jobs/:id" component={EditJobScreen} />

              <Route
                exact
                path="/businesses/:id"
                component={BusinessDetailScreen}
              />
              <Route exact path="/businesses" component={BusinessScreen} />
              <Route
                exact
                path="/businesses/:id"
                component={EditBusinessScreen}
              />
              <Route exact path="/adverts" component={AdvertScreen} />
              <Route exact path="/">
                <Redirect to="/articles" />
              </Route>
            </Switch>
          </div>
        </Paper>
      </main>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100vw",
    backgroundColor: "#fff",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  wrapper: {
    padding: theme.spacing(2.5),
  },
}));
