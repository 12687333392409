import React, { useState, useEffect } from "react";
import {
  TextField,
  makeStyles,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  EditNav,
  CreateNav,
  ModalWrapper,
  UploadModal,
} from "../../../components/components";
import { useActions } from "../../../redux/actions";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import BackupIcon from "@material-ui/icons/Backup";

const AuthorDetailScreen = (props) => {
  const classes = useStyles();
  const [image, setImage] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [authchange, setAuthchange] = useState([]);
  const [name, setName] = useState("");
  const [initialName, setInitialName] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(true);

  const {
    authorUrl,
    authorCreate,
    authorUpdate,
    authors,
    businesses,
  } = useActions();
  const { id } = useParams();
  const history = useHistory();
  const articless = useSelector((state) => state.articles);
  const authorState = useSelector((state) => state.authors);

  useEffect(() => {
    const [selectedBusiness] = authorState.filter(
      (business) => business.profileId === id
    );
    authors();
    businesses();
    //selectedBusiness?.name ? setName(selectedBusiness?.name) : setName("");
    setAuthchange(authorState);
    setName(selectedBusiness?.name);
    setImage(selectedBusiness?.picture);
    localStorage.removeItem("imagesource");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (name) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [name]);

  const authorSource = async () => {
    setModalOpen(false);
    if (localStorage.getItem("imagesource")) {
      setImage(localStorage.getItem("imagesource"));
    }
  };

  const handlecreate = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = "";
    }

    await authorCreate({
      name,
      picture,
    });
    history.push("/authors");
  };

  const handleedit = async () => {
    let picture = localStorage.getItem("imagesource");
    if (picture === null) {
      picture = image;
    }

    await authorUpdate(id, {
      name,
      picture,
    });
    history.push("/authors");
  };

  const { articles } = useActions();

  let checker;
  if (id === "create") {
    checker = true;
  } else {
    checker = false;
  }

  useEffect(() => {
    const fetchArticles = async () => {
      await articles();
    };
    fetchArticles();
  }, [articles]);

  return (
    <div className={classes.root}>
      {checker ? (
        <CreateNav
          text="Create Author"
          handleClick={handlecreate}
          handleCancel={() => history.push("/authors")}
          handleDisable={buttonDisabled}
        />
      ) : (
        <EditNav
          text="Edit Author"
          handleClick={handleedit}
          handleCancel={() => history.push("/authors")}
        />
      )}
      <ModalWrapper
        title="Author Picture"
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        // handleClose={authorSource}
        modalBody={
          <UploadModal
            type="photo"
            maxFiles="1"
            handleClose={authorSource}
            handleCreate={authorUrl}
          />
        }
      />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={10} md={10} xl={12}>
          <Grid container spacing={3}>
            <Grid item xs={10} sm={8} md={6} xl={5}>
              <TextField
                id="filled-multiline-static"
                label="Author name"
                rows={1}
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth={true}
              />
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                color="primary"
                onClick={() => setModalOpen(true)}
                startIcon={<BackupIcon />}
              >
                {image ? "Replace image" : "Upload Image"}
              </Button>
            </Grid>
            <Grid item xs={10} sm={10} md={6} xl={7}>
              {image ? (
                <div className={classes.imageContainer}>
                  <img
                    src={image}
                    className={classes.imageElement}
                    alt="Author"
                  />
                </div>
              ) : (
                <>
                  <Typography variant="overline">
                    No author image uploaded.
                  </Typography>
                  <Typography variant="p" display="block">
                    Please upload a square profile picture (eg. 500 x 500 px)
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
export default AuthorDetailScreen;

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    width: "100%",
    height: "250px",
    display: "flex",
    alignItems: "left",
    justifyContent: "left",
  },
  imageElement: {
    borderRadius: "10px",
    width: "auto",
    maxWidth: "100%",
    height: "auto",
    maxHeight: "100%",
  },
}));
