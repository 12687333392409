import { combineReducers } from "redux";
import articles from "./articles";
import businesses from "./businesses";
import authors from "./authors";
import adverts from "./adverts";
import uploadReducer from "./uploadReducer";
import tags from "./tags";
import session from "./session";
import jobs from "./jobs";

export default combineReducers({
  articles,
  businesses,
  authors,
  adverts,
  tags,
  uploadReducer,
  session,
  jobs,
});
