import React, { useState } from "react";
import { useActions } from "../../redux/actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";

import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@material-ui/core";

import MenuIcon from "@material-ui/icons/Menu";

// STATIC USER DATA IMPORTED FROM USER.JS ** API DEV - REMOVE
import { Logo, Profile } from "../../STATIC/user.js";

const drawerWidth = 240;

const Header = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = props.open;
  const setOpen = props.setOpen;
  const { Logout } = useActions();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleLogOut = async (e) => {
    await Logout();
    setAnchorEl(null);
  };

  const classes = useStyles();
  const session = useSelector((state) => state.session);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar
        style={{ justifyContent: "space-between", alignItems: "middle" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "middle",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>

          <img
            src={Logo}
            className={classes.logo}
            style={{ display: open ? "none" : "block" }}
            alt=""
          />
        </div>
        <div>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <img src={Profile} className={classes.profile} alt="" />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{ marginTop: 20 }}
          >
            <MenuItem
              onClick={() => {
                handleLogOut();
              }}
            >
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  profile: {
    width: "2.6rem",
    height: "2.6rem",
    borderRadius: 30,
    cursor: "pointer",
  },
  logo: {
    width: "8rem",
  },
}));
